// Node modules
import * as Sentry from '@sentry/react';

// Types
import { SettingAttributesEntry } from './';

// Exceptions
import { UnauthorizedException } from '../exceptions/UnauthorizedException';
import { InvalidParametersException } from '../exceptions/InvalidParametersException';
import { ResourceNotFoundException } from '../exceptions/ResourceNotFoundException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { InternalServerErrorException } from '../exceptions/InternalServerErrorException';

// Helpers
import { retryUnauthorizedRequestAfterRefresh } from '..';

/**
 * Database Settings Clearance Doc
 */
export interface SettingsClearancesDB {
	max_upload_size: number;
	types_list: Array<SettingsClearancesType>;
	valid_file_extensions: Array<ValidFileExtension>;
	member_groups: Array<MemberGroup>;
}

/**
 * The Clearance Type entry object
 */
export interface SettingsClearancesType {
	uid: string;
	name: string;
	attributes: SettingsClearancesTypeAttributes;
	archived: null | Date;
}

/**
 * The Clearance Type object attributes
 */
export interface SettingsClearancesTypeAttributes extends SettingAttributesEntry {
	readonly: boolean;
	has_expiration: boolean;
	expiration_years?: number;
}

/**
 * The Clearance Valid File Extensions entry object
 */
export interface ValidFileExtension {
	uid: string;
	name: string;
	extension: string;
	mime_type: string;
	attributes: SettingAttributesEntry;
}

/**
 * The Clearance Member Groups entry object
 */
export interface MemberGroup {
	uid: string;
	name: string;
	requirements: Array<MemberGroupRequirement>;
	attributes: SettingAttributesEntry;
	default?: boolean; // If this member group is default for new members. Assumed to be "false" unless explicitly set to "true"
	archived: null | Date;
}

/**
 * The requirements object for a Member Group entry
 */
export interface MemberGroupRequirement {
	clearance: string;
	required: boolean;
}

export default class SettingsClearances {
	async get(token: string): Promise<SettingsClearancesDB> {
		const response = await fetch(`/api/v1/admin/settings/clearances`, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.get(newToken);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 403) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async update(token: string, data: SettingsClearancesDB): Promise<void> {
		const response = await fetch(`/api/v1/admin/settings/clearances`, {
			method: 'PUT',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify(data),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.update(newToken, data);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 403) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}
}
