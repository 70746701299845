export default class NotificationApprovalNotRequiredException extends Error {
	constructor(message: string) {
		super(message);

		// capturing the stack trace keeps the reference to your error class
		if ('captureStackTrace' in Error) {
			Error.captureStackTrace(this, this.constructor);
		} else {
			console.error(`${this.constructor.name} Does not support captureStackTrace()!`);
		}
	}
}
