/**
 * This exception is used when a user account is locked
 */
export class AccountLockedException extends Error {
	/**
	 * Optional resource id to include in the error
	 */
	resourceID?: string;

	/**
	 * Constructor
	 * @param {string} message the error message
	 * @param {object} resourceID the resource ID
	 */
	constructor(message: string, resourceID?: string) {
		super(message);

		this.resourceID = resourceID;

		// If we support a stack trace, capture it
		if ('captureStackTrace' in Error) {
			Error.captureStackTrace(this, this.constructor);
		} else {
			console.error(`${this.constructor.name} Does not support captureStackTrace()!`);
		}
	}

	/**
	 * Returns the resource ID that caused the error
	 */
	getResourceID() {
		return this.resourceID;
	}
}
