// Node modules
import * as Sentry from '@sentry/react';

// Types
import { settings_data_field_type } from 'api/settings/general';
import { MemberDocumentDB } from './document';

// Exceptions
import { UnauthorizedException } from '../exceptions/UnauthorizedException';
import { InvalidParametersException } from '../exceptions/InvalidParametersException';
import { ResourceNotFoundException } from '../exceptions/ResourceNotFoundException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { InternalServerErrorException } from '../exceptions/InternalServerErrorException';
import { MemberExistsException } from '../exceptions/MemberExistsException';
import { ArchivedMemberExistsException } from '../exceptions/ArchivedMemberExistsException';
import { MemberNotFoundException } from '../exceptions/MemberNotFoundException';
import { ConflictException } from '../exceptions/ConflictException';
import { MemberDocumentAlreadyApprovedException } from '../exceptions/MemberDocumentAlreadyApprovedException';

// Helpers
import { retryUnauthorizedRequestAfterRefresh } from '..';
import Document from './document';
import History from './history';
import Import from './import';

export interface MemberHeaderDB {
	[key: keyof MemberDB]: { name: string; type: settings_data_field_type; primary: boolean };
}

/**
 * Member object
 */
export interface MemberDB {
	docID: string;
	archived: null | Date;

	doc_stats: MemberDocumentStatistics;
	member_group_stats: MemberDocumentStatistics;
	member_group?: string;

	[key: string]: any;
}

/**
 * The Member Document Statistics
 */
export interface MemberDocumentStatistics {
	pending?: number; // The number of documents pending approval
	expiring?: number; // The number of expiring documents
	expired?: number; // The number of documents that have expired
}

export interface MemberDocumentApprovalDB {
	docID: string;
	documentID: string;
	userID: string;
}

export default class Members {
	document: Document;
	history: History;
	import: Import;

	constructor() {
		const documentActions = new Document();
		this.document = documentActions;

		const historyActions = new History();
		this.history = historyActions;

		const importActions = new Import();
		this.import = importActions;
	}

	async getHeaders(token: string): Promise<Array<MemberHeaderDB>> {
		const response = await fetch(`/api/v1/admin/members/headers`, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.getHeaders(newToken);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async list(
		token: string,
		attributes: {
			page?: string;
			page_size?: number;
			sort?: string;
			order?: string;
			query_type?:
				| 'all'
				| 'unarchived'
				| 'archived'
				| 'doc-pending'
				| 'doc-expiring'
				| 'doc-expired'
				| 'member-group-pending'
				| 'member-group-expiring'
				| 'member-group-expired';
		} = {},
	): Promise<Array<MemberDB>> {
		// Validate the attributes
		attributes.page = attributes.page || '';
		attributes.page_size = attributes.page_size || 100;
		attributes.sort = attributes.sort || undefined;
		attributes.order = attributes.order || undefined;

		const response = await fetch(
			`/api/v1/admin/members?offset_id=${attributes.page}&page_size=100${attributes.sort != null ? `&sort=${attributes.sort}` : ''}${
				attributes.order != null ? `&order=${attributes.order}` : ''
			}${attributes.query_type != null ? `&query_type=${attributes.query_type}` : ''}`,
			{
				headers: {
					Authorization: 'Bearer ' + token,
				},
			},
		);

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			if (respObj.member_list == null) {
				return [];
			}

			return respObj.member_list;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.list(newToken, attributes);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async get(token: string, docID: string): Promise<MemberDB> {
		const response = await fetch(`/api/v1/admin/members/${docID}`, {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.get(newToken, docID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async create(token: string, data: MemberDB): Promise<MemberDB> {
		const response = await fetch(`/api/v1/admin/members`, {
			method: 'POST',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify(data),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.create(newToken, data);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 409) {
			throw new ConflictException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async update(token: string, docID: string, data: MemberDB): Promise<MemberDB> {
		const response = await fetch(`/api/v1/admin/members/${docID}`, {
			method: 'PUT',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify(data),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.update(newToken, docID, data);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async archive(token: string, docID: string): Promise<void> {
		const response = await fetch(`/api/v1/admin/members/${docID}/archive`, {
			method: 'PUT',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.archive(newToken, docID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async restore(token: string, docID: string): Promise<void> {
		const response = await fetch(`/api/v1/admin/members/${docID}/restore`, {
			method: 'PUT',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.restore(newToken, docID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async delete(token: string, docID: string): Promise<void> {
		const response = await fetch(`/api/v1/admin/members/${docID}`, {
			method: 'DELETE',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		if (response.status === 200) {
			return;
		} else if (response.status === 400) {
			const respObj = await response.json();
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.delete(newToken, docID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			const respObj = await response.json();
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			const respObj = await response.json();
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			const respObj = await response.json();
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async getMemberDownload(token: string): Promise<Blob> {
		const response = await fetch(`/api/v1/admin/members/download`, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return response.blob();
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.getMemberDownload(newToken);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async search(token: string, search: string): Promise<Array<MemberDB>> {
		const response = await fetch(`/api/v1/admin/members/search`, {
			method: 'POST',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify({ search: search }),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			if (respObj.search_results == null) {
				return [];
			}

			return respObj.search_results;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.search(newToken, search);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async getStatus(token: string, bsaID: number): Promise<void> {
		const response = await fetch(`/api/v1/admin/members/status`, {
			method: 'POST',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify({ 'bsa-id': bsaID }),
		});

		// Check the response
		if (response.status === 200) {
			// Member exists
			const respObj = await response.json();
			throw new MemberExistsException(respObj.docID);
		} else if (response.status === 461) {
			// Member is archived
			const respObj = await response.json();
			throw new ArchivedMemberExistsException(respObj.docID);
		} else if (response.status === 404) {
			// Member not found
			throw new MemberNotFoundException('');
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.getStatus(newToken, bsaID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response returned unauthorized');
				} else {
					throw error;
				}
			}
		}
	}

	async listPendingApproval(token: string): Promise<Array<MemberDocumentApprovalDB>> {
		const response = await fetch(`/api/v1/admin/members/pending-approval`, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			if (respObj.pending_approval == null) {
				return [];
			}

			return respObj.pending_approval;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.listPendingApproval(newToken);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}

	async getPendingApproval(token: string, docID: string): Promise<{ memberData: MemberDB; docData: MemberDocumentDB; docURL: string }> {
		const response = await fetch(`/api/v1/admin/members/pending-approval/${docID}`, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			try {
				const newToken = await retryUnauthorizedRequestAfterRefresh();
				return this.getPendingApproval(newToken, docID);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					throw new UnauthorizedException('Request response retry returned unauthorized');
				} else {
					throw error;
				}
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 461) {
			throw new MemberDocumentAlreadyApprovedException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	}
}
