import React, { useState, useEffect, useContext } from 'react';

import { Button, Modal, Form, Nav, NavItem, NavLink, TabContent, TabPane, ModalHeader } from 'reactstrap';

import { Events } from '@mouseware/lib-events';

import { setting } from '../../../../../api';
import { validateInt } from '../../../../../validator';

import UserContext from '../../../../UserContext';
import { NotificationTab } from './notification-record-overlay/NotificationTab';
import { SettingsTab } from './notification-record-overlay/SettingsTab';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { SettingsNotificationsListEntry } from 'api/settings/notifications';
import { SettingsClearancesType } from 'api/settings/clearances';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	show: boolean;
	editRecord: SettingsNotificationsListEntry;
	onSave: (inputData: SettingsNotificationsListEntry) => void;
	onClose: () => void;
}

export const NotificationRecordOverlay = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const { editRecord, onSave, show, onClose } = props;

	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState<SettingsNotificationsListEntry>(
		editRecord != null ? editRecord : { uid: '', name: '', body: '', subject: '', event: Events.DOCUMENT_ADD, recipients: [] },
	);
	const [settingsClearanceFields, setSettingsClearanceFields] = useState<Array<SettingsClearancesType>>([]);
	const [settingsDynamicDataFields, setSettingsDynamicDataFields] = useState<Array<SettingsDataField>>([]);
	const [canSave, setCanSave] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);
	const [activeTab, setActiveTab] = useState('settings');
	const [tabErrorState, setTabErrorState] = useState<{ settings?: string; notifications?: string }>({
		settings: undefined,
		notifications: undefined,
	});

	useEffect(() => {
		const getContents = async () => {
			setIsLoading(true);

			try {
				const settingsGeneral = await setting.general.get(jwt);
				setSettingsDynamicDataFields(settingsGeneral.data_fields);

				const settingsClearances = await setting.clearances.get(jwt);
				setSettingsClearanceFields(settingsClearances.types_list);
			} catch (error) {
				// TODO: Handle better
				console.error('error');
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};

		getContents();
		return () => {
			setFormSaveInProgress(false);
			setData({ uid: '', name: '', body: '', subject: '', event: Events.DOCUMENT_ADD, recipients: [] });
		};
	}, []);

	/**
	 * This function will check to make sure that we are good to submit the form anytime that data is changed
	 */
	useEffect(() => {
		let canSave = true;

		// Do the form validation
		if (data.name == null || data.name === '') {
			canSave = false;
		}

		if (data.event == null || (data as any).event === '') {
			canSave = false;
		}

		if (data.recipients == null || data.recipients.length === 0) {
			canSave = false;
		} else {
			let hasExpirationCondition = false;

			for (const recipient of data.recipients) {
				if (
					recipient.recipient_type == null ||
					(recipient as any).recipient_type === '' ||
					(recipient.recipient_type !== 'to' && recipient.recipient_type !== 'cc')
				) {
					canSave = false;
				}

				if (
					recipient.recipient == null ||
					(recipient as any).recipient === '' ||
					(recipient.recipient !== 'user' && recipient.recipient !== 'any-member' && recipient.recipient !== 'administrator')
				) {
					canSave = false;
				}

				if (recipient.conditions != null && recipient.conditions.length > 0) {
					for (const condition of recipient.conditions) {
						if (condition.field === 'document-expiration') {
							if (condition.value == null || condition.value === '' || !validateInt(condition.value) || condition.value < 0) {
								canSave = false;
							}

							hasExpirationCondition = true;
						}
					}
				}
			}

			if (data.event === 'document.expiring' && hasExpirationCondition === false) {
				canSave = false;
			}
		}

		// Additional checks - make sure notification doesn't have a expiration shortcode if not an expiration event
		if (data.event !== 'document.expiring' && data.event !== 'document.expired') {
			// It is not an expiration event
			if (data.subject != null && data.subject.includes('[Clearance Expiration Date]')) {
				// It exists. Error
				setTabErrorState({ notifications: '[Clearance Expiration Date] exists in subject and will not be replaced' });
				canSave = false;
			}

			if (data.body != null && data.body.includes('[Clearance Expiration Date]')) {
				// It exists. Error
				setTabErrorState({ notifications: '[Clearance Expiration Date] exists in body and will not be replaced' });
				canSave = false;
			}
		} else {
			setTabErrorState({ notifications: undefined });
		}

		if (canSave === true) {
			setTabErrorState({ notifications: undefined });
		}

		setCanSave(canSave);
	}, [data]);

	useEffect(() => {
		if (editRecord != null) {
			setData(editRecord);
		} else {
			setData({ uid: '', name: '', body: '', subject: '', event: Events.DOCUMENT_ADD, recipients: [] });
		}
	}, [editRecord]);

	/**
	 * This function will update the value in the React state
	 * @param {string} fieldName
	 * @param {*} newValue
	 */
	const handleInputFieldChange = async (fieldName: keyof SettingsNotificationsListEntry, newValue: any) => {
		let newData = { ...data };

		(newData as any)[fieldName] = newValue;

		setData(newData);
	};

	/**
	 *
	 */
	const handleOnSaveClick = async () => {
		if (data.uid == null || data.uid === '') {
			data.uid = data.name.replace(/\s+/g, '-').toLowerCase();
		}

		setFormSaveInProgress(true);

		try {
			await onSave(data);
		} catch (err) {
			console.error(err);
		}
	};

	/**
	 * This function handles the click of a tab pane
	 */
	const handleTabClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabName: 'notification' | 'settings') => {
		setActiveTab(tabName);

		// We don't want to have the actual <a> perform an action
		e.preventDefault();
	};

	/**
	 * The render function
	 */
	return (
		<Modal className="modal-xl notification-settings-record-overlay" isOpen={show} toggle={() => onClose()}>
			<ModalHeader toggle={() => onClose()}>{data.uid == null || data.uid === '' ? 'Add' : 'Edit'} Notification</ModalHeader>
			<div className="modal-body">
				{isLoading ? (
					<>
						<FaSpinner size={12} color="#ccc" className="fa-spin" />
					</>
				) : (
					<Form>
						<div className="nav-tabs-navigation">
							<div className="nav-tabs-wrapper">
								<Nav id="tabs" role="tablist" tabs>
									<NavItem>
										<NavLink
											data-toggle="tab"
											href="#"
											role="tab"
											className={activeTab === 'settings' ? 'active' : ''}
											onClick={(e) => handleTabClick(e, 'settings')}
										>
											Settings {tabErrorState.settings != null ? <i className="fa fa-exclamation-triangle" aria-hidden="true" /> : ''}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											data-toggle="tab"
											href="#"
											role="tab"
											className={activeTab === 'notification' ? 'active' : ''}
											onClick={(e) => handleTabClick(e, 'notification')}
										>
											Message {tabErrorState.notifications != null ? <i className="fa fa-exclamation-triangle" aria-hidden="true" /> : ''}
										</NavLink>
									</NavItem>
								</Nav>
							</div>
						</div>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="settings" role="tabpanel">
								<SettingsTab
									data={data}
									dynamicDataFields={settingsDynamicDataFields}
									clearanceFields={settingsClearanceFields}
									handleInputFieldChange={handleInputFieldChange}
								/>
							</TabPane>
							<TabPane tabId="notification" role="tabpanel">
								<NotificationTab
									data={data}
									dynamicDataFields={settingsDynamicDataFields}
									handleInputFieldChange={handleInputFieldChange}
									error={tabErrorState.notifications}
								/>
							</TabPane>
						</TabContent>
					</Form>
				)}
			</div>
			<div className="modal-footer justify-content-center">
				<Button
					className="btn-round"
					color="primary"
					type="button"
					onClick={() => {
						handleOnSaveClick();
					}}
					disabled={!canSave || formSaveInProgress}
				>
					{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : data.uid == null || data.uid === '' ? 'Add' : 'Update'}
				</Button>
				<Button
					className="btn-round"
					color="secondary"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						onClose();
					}}
				>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};
