import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { ButtonGroup, Button, Card, CardHeader, CardBody, CardTitle, Table, Row, Col, UncontrolledTooltip } from 'reactstrap';

import { administrators } from '../../../../api';
import UserContext from '../../../UserContext';
import toast from 'react-hot-toast';
import { AdministratorRoleDB } from 'api/administrators/roles';
import { ModalOverlay, ModalOverlayDelete } from 'components/Modal/Modal';
import { FaInfo } from 'react-icons/fa';
import { UnauthorizedException } from '../../../../api/exceptions/UnauthorizedException';
import { useNavigate } from 'react-router-dom';

export const RolesTable = () => {
	const navigate = useNavigate();
	const { jwt, setDisplayUserLoginOverlay } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [loadingError, setLoadingError] = useState<boolean>(false);
	const [data, setData] = useState<Array<AdministratorRoleDB>>([]);
	const [copyRecordID, setCopyRecordID] = useState<string | null>(null);
	const [tableRecordDeleteID, setTableRecordDeleteID] = useState<string | null>(null);

	useEffect(() => {
		const getContents = async () => {
			try {
				const data = await administrators.roles.list(jwt);
				setData(data);
				setIsLoading(false);
			} catch (error) {
				setLoadingError(true);
				if (error instanceof UnauthorizedException) {
					setDisplayUserLoginOverlay(true, () => {
						setLoadingError(false);
					});
				} else {
					console.error(error);
					Sentry.captureException(error);
					throw error;
				}
			}
		};

		getContents();
	}, []);

	const handleToolbarAddClick = async () => {
		navigate({ pathname: `${location.pathname}/add` });
	};

	const handleRecordEditClick = async (docID: string) => {
		navigate({ pathname: `${location.pathname}/${docID}` });
	};

	const handleRecordDeleteClick = async (docID: string) => {
		setTableRecordDeleteID(docID);
	};

	const handlTableRecordDeleteConfirm = async () => {
		try {
			await administrators.roles.delete(jwt, tableRecordDeleteID!);

			const recordIndex = data.findIndex((record) => record.docID === tableRecordDeleteID);

			if (recordIndex > -1) {
				data.splice(recordIndex, 1);
			}

			setData(data);
			setTableRecordDeleteID(null);
		} catch (error) {
			console.error('error');
			console.error(error);
			Sentry.captureException(error);
		}
	};

	const handlTableRecordDeleteConfirmCancel = async () => {
		setTableRecordDeleteID(null);
	};

	const handleRecordCopyClick = (docID: string) => {
		setCopyRecordID(docID);
	};

	const handleCopyConfirm = async () => {
		try {
			const response = await administrators.roles.copy(jwt, copyRecordID!);

			const newData = [...data];
			newData.push(response);
			setData(newData);
			setCopyRecordID(null);
			toast.success('Role copied');
		} catch (error) {
			console.error(error);
			Sentry.captureException(error);
			toast.error('Failed to copy role');
		}
	};

	const handleCopyCancel = () => {
		setCopyRecordID(null);
	};

	return (
		<div className="roles-table">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<ButtonGroup className="pull-right">
								<Button className="btn-icon" id={`roles-add-button`} color="info" onClick={() => handleToolbarAddClick()}>
									<i className="fa fa-plus" />
								</Button>
							</ButtonGroup>
							<UncontrolledTooltip delay={0} target={`roles-add-button`}>
								Create Role
							</UncontrolledTooltip>
							<CardTitle tag="h4">Roles</CardTitle>
						</CardHeader>
						<CardBody>
							<Table responsive>
								<thead className="text-primary">
									<tr>
										<th style={{ width: '40%' }}>Name</th>
										<th className="text-right">Actions</th>
									</tr>
								</thead>
								<tbody>
									{loadingError ? (
										<tr>
											<td colSpan={5}>There was an error loading data.</td>
										</tr>
									) : isLoading ? (
										<tr>
											<td colSpan={5}>Loading...</td>
										</tr>
									) : (
										<>
											{data.length > 0 ? (
												data.map((record) => {
													const { docID } = record;

													return (
														<tr key={docID}>
															<td>{record.name}</td>
															<td className="text-right">
																<Button
																	className="btn-icon"
																	color="info"
																	id={`roles-${docID}-edit`}
																	size="sm"
																	type="button"
																	onClick={() => handleRecordEditClick(docID)}
																>
																	<i className="fa fa-edit" />
																</Button>{' '}
																<UncontrolledTooltip delay={0} target={`roles-${docID}-edit`}>
																	Edit
																</UncontrolledTooltip>
																<Button
																	className="btn-icon"
																	color="secondary"
																	id={`roles-${docID}-copy`}
																	size="sm"
																	type="button"
																	onClick={() => handleRecordCopyClick(docID)}
																>
																	<i className="fa fa-copy" />
																</Button>{' '}
																<UncontrolledTooltip delay={0} target={`roles-${docID}-copy`}>
																	Copy
																</UncontrolledTooltip>
																<Button
																	className="btn-icon"
																	color="danger"
																	id={`roles-${docID}-delete`}
																	size="sm"
																	type="button"
																	disabled={true}
																	onClick={() => handleRecordDeleteClick(docID)}
																>
																	<i className="fa fa-times" />
																</Button>{' '}
																<UncontrolledTooltip delay={0} target={`roles-${docID}-delete`}>
																	Delete
																</UncontrolledTooltip>
															</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td colSpan={5}>No records found.</td>
												</tr>
											)}
										</>
									)}
								</tbody>
							</Table>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<ModalOverlay
				show={copyRecordID != null}
				icon={FaInfo}
				title="Role Copy"
				primaryActionCallback={handleCopyConfirm}
				primaryActionText="Yes, Copy"
				secondaryActionCallback={handleCopyCancel}
			>
				Are you sure you want to copy this role?
			</ModalOverlay>
			<ModalOverlayDelete
				show={tableRecordDeleteID != null}
				title="Role Delete"
				primaryActionCallback={handlTableRecordDeleteConfirm}
				secondaryActionCallback={handlTableRecordDeleteConfirmCancel}
			>
				This action cannot be undone.
			</ModalOverlayDelete>
		</div>
	);
};
