import React from 'react';
import { Routes, Route, useMatch, useLocation } from 'react-router-dom';

import { CardTitle } from 'reactstrap';

import { DocumentList } from './clearances-tab/DocumentList';
import { DocumentUpload } from './clearances-tab/DocumentUpload';
import { DocumentDownload } from './clearances-tab/DocumentDownload';

export const ClearancesTab = () => {
	console.log('ClearancesTab');
	return (
		<div className="clearances-tab">
			<CardTitle tag="h4" style={{ marginTop: 0, marginBottom: '.5rem' }}>
				Clearances
			</CardTitle>
			<Routes>
				<Route path={`upload`} element={<DocumentUpload />} />
				<Route path={`download/:documentID`} element={<DocumentDownload />} key={'documentDownload'} />
				<Route index element={<DocumentList />} />
			</Routes>
		</div>
	);
};
