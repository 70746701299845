import React, { useState } from 'react';

import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { TableContents } from './preview-data/TableContents';
import { FaSpinner } from 'react-icons/fa';
import { SettingsDataField } from 'api/settings/general';
import { SettingsClearancesType } from 'api/settings/clearances';
import { MemberImportDB } from 'api/members/import';
import { MemberImportTemplateField } from 'api/members/templates';

interface Props {
	columnMappings: Array<MemberImportTemplateField>;
	onConfirm: () => void;
	data: MemberImportDB;
	clearanceSettingsFields: Array<SettingsClearancesType>;
	dynamicSettingsDataFields: Array<SettingsDataField>;
}

export const PreviewData = (props: Props) => {
	const { columnMappings, onConfirm, data, clearanceSettingsFields, dynamicSettingsDataFields } = props;
	const [activeTab, setActiveTab] = useState('add');
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);

	const handleTabClick = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		tabName: 'add' | 'update' | 'conflict' | 'skip' | 'bad' | 'archive' | 'unarchive'
	) => {
		setActiveTab(tabName);

		// We don't want to have the actual <a> perform an action
		e.preventDefault();
	};

	const handleConfirmClick = async () => {
		setFormSaveInProgress(true);
		await onConfirm();
		setFormSaveInProgress(false);
	};

	return (
		<>
			<div className="nav-tabs-navigation">
				<div className="nav-tabs-wrapper">
					<Nav id="tabs" role="tablist" tabs>
						<NavItem>
							<NavLink
								data-toggle="tab"
								href="#"
								role="tab"
								className={activeTab === 'add' ? 'active' : ''}
								onClick={(e) => handleTabClick(e, 'add')}
							>
								Add ({data.record_counts!.add_records})
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								data-toggle="tab"
								href="#"
								role="tab"
								className={activeTab === 'update' ? 'active' : ''}
								onClick={(e) => handleTabClick(e, 'update')}
							>
								Update ({data.record_counts!.update_records})
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								data-toggle="tab"
								href="#"
								role="tab"
								className={activeTab === 'conflict' ? 'active' : ''}
								onClick={(e) => handleTabClick(e, 'conflict')}
							>
								Conflict ({data.record_counts!.conflict_records})
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								data-toggle="tab"
								href="#"
								role="tab"
								className={activeTab === 'skip' ? 'active' : ''}
								onClick={(e) => handleTabClick(e, 'skip')}
							>
								Skip ({data.record_counts!.skip_records})
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								data-toggle="tab"
								href="#"
								role="tab"
								className={activeTab === 'bad' ? 'active' : ''}
								onClick={(e) => handleTabClick(e, 'bad')}
							>
								Bad ({data.record_counts!.bad_records})
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								data-toggle="tab"
								href="#"
								role="tab"
								className={activeTab === 'archive' ? 'active' : ''}
								onClick={(e) => handleTabClick(e, 'archive')}
							>
								Archive ({data.record_counts!.archive_records})
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								data-toggle="tab"
								href="#"
								role="tab"
								className={activeTab === 'unarchive' ? 'active' : ''}
								onClick={(e) => handleTabClick(e, 'unarchive')}
							>
								Unarchive ({data.record_counts!.unarchive_records})
							</NavLink>
						</NavItem>
					</Nav>
				</div>
			</div>
			<TabContent>
				<TabPane>
					<TableContents
						tabID={activeTab}
						clearanceSettingsFields={clearanceSettingsFields}
						dynamicSettingsDataFields={dynamicSettingsDataFields}
						data={data}
					/>
				</TabPane>
			</TabContent>
			<hr />
			<Button
				className="btn-round"
				color="primary"
				data-dismiss="modal"
				type="button"
				onClick={() => {
					handleConfirmClick();
				}}
				disabled={formSaveInProgress}
			>
				{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Confirm'}
			</Button>
		</>
	);
};
