import React, { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import UserContext from '../../../UserContext';
import toast from 'react-hot-toast';

import { frontend } from '../../../../api';
import { FaSpinner } from 'react-icons/fa';

export const DocumentDownload = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { documentID } = useParams<{ documentID: string }>();

	useEffect(() => {
		const getContents = async () => {
			try {
				const downloadFileObj = await frontend.downloadClearanceFile(user!.token!, documentID ?? '');

				// Create blob link to download
				const url = window.URL.createObjectURL(downloadFileObj.buffer);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${downloadFileObj.filename}`);

				// Append to html page
				document.body.appendChild(link);

				// Force download
				link.click();

				// Clean up and remove the link
				link.parentNode!.removeChild(link);
			} catch (err) {
				toast.error('Failed to download file');
				Sentry.captureException(err);
			} finally {
				navigate('..');
			}
		};

		getContents();
	}, []);

	return (
		<div className="file-processing">
			<div className="processing-content">
				<FaSpinner size={12} className="fa-spin" />
			</div>
		</div>
	);
};
