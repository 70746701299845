import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { Alert, Card, CardHeader, CardBody, CardTitle, Row, Col, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import toast from 'react-hot-toast';

import { administrators } from '../../../api';
import UserContext from '../../UserContext';

import { DetailsEdit } from './partials/DetailsEdit';
import { DetailsView } from './partials/DetailsView';
import { FaSpinner } from 'react-icons/fa';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AdministratorRoleDB } from 'api/administrators/roles';
import { AdministratorUserDB } from 'api/administrators/users';

export const UserDetails = () => {
	const navigate = useNavigate();
	const { hash: locationHash, pathname: locationPathname, search: locationSearch } = useLocation();
	const { uid: docID } = useParams<{ uid?: string }>();

	const { jwt } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [hasFormChanged, setHasFormChanged] = useState(false);

	const [canSave, setCanSave] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isEditDetailsSaving, setIsEditDetailsSaving] = useState(false);
	const [data, setData] = useState<AdministratorUserDB>({ docID: '', role: '', name: '', email: '', password: '', archived: null });
	const [roles, setRoles] = useState<Array<AdministratorRoleDB>>([]);

	const [isEditMode, setIsEditMode] = useState(false);

	useEffect(() => {
		const getContents = async () => {
			setIsLoading(true);
			try {
				const rolesList = await administrators.roles.list(jwt);
				setRoles(rolesList);

				if (docID != null && docID !== 'add') {
					const userDetails = await administrators.users.get(jwt, docID);
					setData(userDetails);
				}
			} catch (error) {
				console.error('error');
				console.error(error);
				Sentry.captureException(error);
			} finally {
				setIsLoading(false);
			}
		};

		getContents();
	}, []);

	useEffect(() => {
		let canSaveTmp = false;

		if (data.name != null && data.name != '') {
			canSaveTmp = true;
		}

		setCanSave(canSaveTmp);
	}, [data]);

	async function handleFormSave() {
		setIsEditDetailsSaving(true);
		try {
			if (docID == null || docID === 'add') {
				const response = await administrators.users.create(jwt, data);

				setHasFormChanged(false);
				setData(response);
				toast.success('Added Successfully');

				navigate({
					pathname: generatePath(locationPathname, {
						uid: response.docID,
					}),
				});
			} else {
				const response = await administrators.users.update(jwt, data.docID, data);

				setHasFormChanged(false);
				setData(response);
				toast.success('Save Successful');
			}
		} catch (err: any) {
			if (err.message != null) {
				toast.error(err.message);
			} else {
				toast.error('Error saving');
				Sentry.captureException(err);
			}
		} finally {
			setIsEditDetailsSaving(false);
			setIsEditMode(false);
		}
	}

	async function handleUnlockClick() {
		try {
			const response = await administrators.users.unlock(jwt, data.docID);
		} catch (e) {
			console.error(e);
		}
	}

	async function handleResetPasswordClick() {
		try {
			const response = await administrators.users.requestPasswordReset(jwt, data.docID);
		} catch (e) {
			console.error(e);
		}
	}

	async function handleArchiveClick() {
		try {
			await administrators.users.archive(jwt, data.docID);
			toast.success('Administrator archived');
			setData((previousData) => {
				return { ...previousData, archived: new Date() };
			});
		} catch (error) {
			console.error('error');
			console.error(error);
			Sentry.captureException(error);
		}
	}

	async function handleErrorCloseClick() {
		setErrorMessage(null);
	}

	async function onDataUpdate(fieldName: keyof AdministratorUserDB, newValue: any) {
		const newData = { ...data };
		newData[fieldName] = newValue;

		setData(newData);
		setHasFormChanged(true);
	}

	return (
		<Row>
			<Col md="12">
				<Card>
					<CardHeader>
						<div className="pull-right">
							{!isEditMode ? (
								<UncontrolledDropdown className="btn-link btn-large">
									<DropdownToggle aria-haspopup={true} color="default" data-toggle="dropdown" id="navbarDropdownMenuLink">
										<i className="fa fa-ellipsis-v" />
									</DropdownToggle>
									<DropdownMenu persist aria-labelledby="navbarDropdownMenuLink" right>
										<DropdownItem onClick={() => setIsEditMode(true)}>
											<i className="fa fa-pencil" /> Edit
										</DropdownItem>
										<DropdownItem
											disabled={data != null && data.attributes != null && data.attributes.isLocked === true}
											onClick={() => handleUnlockClick()}
										>
											<i className="fa fa-unlock" /> Unlock
										</DropdownItem>
										<DropdownItem onClick={() => handleResetPasswordClick()}>
											<i className="fa fa-key" /> Reset Password
										</DropdownItem>
										<DropdownItem disabled={data.archived != null} onClick={() => handleArchiveClick()}>
											<i className="fa fa-archive" /> Archive
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							) : (
								<>
									<Button color="link" size="sm" className="pull-right" onClick={() => setIsEditMode(false)} disabled={isEditDetailsSaving}>
										Cancel
									</Button>
									<Button
										color="info"
										size="sm"
										className="pull-right"
										onClick={() => handleFormSave()}
										disabled={!canSave || isEditDetailsSaving}
									>
										{!isEditDetailsSaving ? 'Save' : <FaSpinner size={12} color="#ccc" className="fa-spin" />}
									</Button>
								</>
							)}
						</div>
						<CardTitle tag="h4">{errorMessage ? <div>Error</div> : isLoading ? <div>Loading</div> : data.name}</CardTitle>
					</CardHeader>
					<CardBody>
						{errorMessage ? (
							<div>There was an error loading data.</div>
						) : isLoading ? (
							<div>Loading...</div>
						) : (
							<>
								{errorMessage != null ? (
									<Alert
										color={'danger'}
										toggle={() => {
											handleErrorCloseClick();
										}}
									>
										{errorMessage}
									</Alert>
								) : (
									<></>
								)}
								{data.archived != null ? <Alert color={'warning'}>Administrator is archived</Alert> : <></>}
								{!isEditMode ? (
									<DetailsView data={data} roles={roles} />
								) : (
									<DetailsEdit data={data} roles={roles} onChange={onDataUpdate} setCanSave={setCanSave} />
								)}
							</>
						)}
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};
