import React, { useState } from 'react';

import { Alert, Button, Input, FormGroup, Label, Row, Col, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SettingsNotificationsListEntry } from 'api/settings/notifications';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	data: SettingsNotificationsListEntry;
	dynamicDataFields: Array<SettingsDataField>;
	handleInputFieldChange: (fieldName: keyof SettingsNotificationsListEntry, newValue: any) => void;
	error?: string;
}

export const NotificationTab = (props: Props) => {
	const { data, dynamicDataFields, handleInputFieldChange, error } = props;
	const [popoverFields, setPopoverFields] = useState({
		'notification-record-overlay-email-subject': false,
		'notification-record-overlay-email-body': false,
	});

	/**
	 *
	 */
	const togglePopoverField = (popoverFieldName: 'notification-record-overlay-email-subject' | 'notification-record-overlay-email-body') => {
		let newPopoverFields = { ...popoverFields };

		if (newPopoverFields[popoverFieldName] != null) {
			newPopoverFields[popoverFieldName] = !newPopoverFields[popoverFieldName];
		} else {
			console.error(`Cannot find "${popoverFieldName}" in popoverFields state array`);
			newPopoverFields[popoverFieldName] = false;
		}

		setPopoverFields(newPopoverFields);
	};

	/**
	 * The render function
	 */
	return (
		<>
			{error != null ? (
				<Alert color="danger">
					<span>{error}</span>
				</Alert>
			) : (
				<></>
			)}
			<Row>
				<Col md={12}>
					<FormGroup>
						<div className="pull-right variables-button">
							<Button
								color="link"
								id={`notification-record-overlay-email-subject`}
								type="button"
								size="sm"
								onClick={(e) => (e as any).target.focus()}
							>
								Variables
							</Button>
						</div>
						<Label for="email_subject">Email Subject</Label>
						<Input
							type="text"
							name="email_subject"
							id="email_subject"
							placeholder="Enter Name"
							onChange={(e) => {
								handleInputFieldChange('subject', e.target.value);
							}}
							value={data.subject != null ? data.subject : ''}
						/>
						<UncontrolledPopover
							popperClassName="notification-settings-record-overlay-popover"
							placement="left"
							trigger="focus"
							isOpen={popoverFields['notification-record-overlay-email-subject']}
							target="notification-record-overlay-email-subject"
							toggle={() => {
								togglePopoverField('notification-record-overlay-email-subject');
							}}
						>
							<PopoverHeader>Available Fields</PopoverHeader>
							<PopoverBody>
								<strong>[Date]</strong> Today's date in the MM/DD/YYYY format
								<br />
								<strong>[Clearance Name]</strong> The name of the clearance that is expiring
								<br />
								{data.event != null && (data as any).event === 'expiration' ? (
									<>
										<strong>[Clearance Expiration Date]</strong> The expiring clearance date in the MM/DD/YYYY format
										<br />
									</>
								) : (
									<></>
								)}
								{dynamicDataFields != null ? (
									dynamicDataFields.map((field) => {
										const { uid, name, description } = field;

										return (
											<span key={`subject-${uid}`}>
												<strong>[{name}]</strong> {description}
												<br />
											</span>
										);
									})
								) : (
									<></>
								)}
							</PopoverBody>
						</UncontrolledPopover>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<FormGroup>
						<div className="pull-right variables-button">
							<Button
								color="link"
								id={`notification-record-overlay-email-body`}
								type="button"
								size="sm"
								onClick={(e) => (e as any).target.focus()}
							>
								Variables
							</Button>
						</div>
						<Label for="email_body">Email Body</Label>
						<CKEditor
							editor={ClassicEditor}
							config={{
								toolbar: [
									'heading',
									'|',
									'bold',
									'italic',
									'|',
									'link',
									'|',
									'outdent',
									'indent',
									'|',
									'bulletedList',
									'numberedList',
									'insertTable',
									'|',
									'blockQuote',
									'|',
									'undo',
									'redo',
								],
							}}
							data={data.body != null ? data.body : ''}
							onChange={(event: any, editor: any) => {
								const data = editor.getData();

								handleInputFieldChange('body', data);
							}}
						/>
						<UncontrolledPopover
							popperClassName="notification-settings-record-overlay-popover"
							placement="left"
							trigger="focus"
							isOpen={popoverFields['notification-record-overlay-email-body']}
							target="notification-record-overlay-email-body"
							toggle={() => {
								togglePopoverField('notification-record-overlay-email-body');
							}}
						>
							<PopoverHeader>Available Fields</PopoverHeader>
							<PopoverBody>
								<strong>[Date]</strong> Today's date in the MM/DD/YYYY format
								<br />
								<strong>[Clearance Expiration Date]</strong> The expiring clearance date in the MM/DD/YYYY format
								<br />
								<strong>[Clearance Name]</strong> The name of the clearance that is expiring
								<br />
								<strong>[Clearance List]</strong> List of all clearances submitted and the date of expiration
								<br />
								<strong>[Website URL]</strong> The URL to the website (i.e. {process.env.REACT_APP_CLIENT_HOME_PAGE})
								<br />
								{dynamicDataFields != null ? (
									dynamicDataFields.map((field) => {
										const { uid, name, description } = field;

										return (
											<span key={`body-${uid}`}>
												<strong>[{name}]</strong> {description}
												<br />
											</span>
										);
									})
								) : (
									<></>
								)}
							</PopoverBody>
						</UncontrolledPopover>
					</FormGroup>
				</Col>
			</Row>
		</>
	);
};
